import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function ContactWeb() {
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');

  const submit = async (event) => {
    event.preventDefault();
    const formData = { name, email, comment };
setEmail('');setName('');setComment('');
    console.log(formData);


    try {
      //const response = await axios.post('http://localhost:4000/api/formdata', formsubmit);
      const response = await axios.post('https://backend.quantumsoftdev.in/api/formsubmit', formData);
      console.log(response.data);
      alert('Form submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error.response ? error.response.data : error.message);
      alert('Failed to submit form. Please try again.');
    }
  };

  useEffect(() => {
    function handleResize() {
      setMinWidth(Math.min(window.innerWidth, maxWidth));
      setMaxWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="container-fluid min-vh-50 py-2"
      style={{ height: '400px', background: 'rgb(0,0,0)' }}
      id="contact"
    >
      <div className="container w-75 m-10 text-center">
        <span className="h1 text-light">Get In Touch</span>
      </div>
      <hr />
      <div className={`container m-10 ${maxWidth >= 813 ? 'w-50' : 'w-75'}`}>
        <div className="row mt-4">
          <div className="col-12 col-md-6 d-flex flex-column justify-content-md-between">
            <label className="text-light form-label">Email :</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              placeholder="name@example.com"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <label className="text-light form-label">Name :</label>
            <input
              type="text"
              className="form-control"
              placeholder="Full Name.."
              id="name" value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 d-flex flex-column">
            <label htmlFor="comment" className="form-label text-light">
              Comments :
            </label>
            <textarea
              onChange={(event) => {
                setComment(event.target.value);
              }}
              className="form-control"
              id="comment"
              rows="3"
value={comment}
              placeholder="Write Your Comments...."
            ></textarea>
          </div>
        </div>
        <div className="row mt-4 w-100">
          <div className="d-flex flex-row justify-content-center">
            <button className="w-30 btn btn-secondary" onClick={submit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
