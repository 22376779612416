import React from 'react';

export default function Alert({onHideAlert}) {

  

  // Function to manage the alert visibility
  const manageAlert = () => {
    onHideAlert(); // Set the alert to "invisible"
  };

  return (
    <div className={`text-center `}>
      <div className="alert alert-info" role="alert">
        <div className="row">
          <div className="col-md-8 col-10 text-end">
            New Product Launched For Student & Developers{' '}
            <a href="#newProduct" className="alert-link">CHECK IT NOW!</a>.
          </div>
          <div className="col-md-4 col-2 text-end" onClick={manageAlert}>
            {/* Close button */}
            <img style={{ height: '25px', width: '25px' }} src="cross.WebP" alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
}
