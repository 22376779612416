import React from 'react'

function FooterWeb({linkedin,github}) {
  const styleFooterWeb = {
    backgroundColor: 'rgb(0,0,0)'
  };
  return (
      <div className="container text-center " style={styleFooterWeb}>
        <div className='mt-5'><span className='h5 text-muted'>Email:</span>
        <a href="official@quantumsoftdev.in"><span className='h6 text-light'> official@quantumsoftdev.in</span></a> </div>
        
        <div className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
            
            </a>
            <span className="mb-3 mb-md-0 text-muted">© QuantumSoftDev 2024 </span>
            </div>
            <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
              <li className="ms-3"><a className="text-muted" href="official@quantumsoftdev.in">Email</a></li>
              <li className="ms-3"><a className="text-muted" target='_blank' href={linkedin}>Linkedin</a></li>
              <li className="ms-3"><a className="text-muted" href={github}>GitHub</a></li>
            </ul>
        </div>
      </div>
    )
   }

export default FooterWeb