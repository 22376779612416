import React,{useState,useEffect} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const innerImg = {
  borderRadius: '15px',
  objectFit: "cover",
  width: '100%',/*window.screen.width<=480?'320px':'90%',320px*/
  height: 'auto'/*window.screen.width<=480?'180px':'auto'180px */
};

const data = [{ id:1,img: 'NewProduct/1.WebP' },
   { id:2,img: 'NewProduct/2.WebP' },
    { id:3,img: 'NewProduct/3.WebP' },
     { id:4,img: 'NewProduct/4.WebP' }];

const NewProduct = () => {
  const [minWidth, setMinWidth]= useState(0);
  const [maxWidth, setMaxWidth]= useState(0);

  useEffect(()=>{
    function handleResize() {
      setMinWidth(Math.min(window.innerWidth, maxWidth));
      setMaxWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return()=>{
      window.removeEventListener('resize',handleResize);
    };
  },[]);
  

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:2000,
    arrows: false
    
  };{/*responsive:[{
    breakpoint:900,settings:{
      slidesToShow:2,
    }
  },{breakpoint:700,settings:{
    slidesToShow:1
  }}] */}

  return (
      <div className="" style={{  }}>
        

        <Slider {...settings}>
          {data.map((d) => (
            <div className='py-4 d-flex justify-content-center  ' key={d.id}>
              <img src={d.img}  style={innerImg}/>
            </div>
          ))}
        </Slider>
      </div>
      
  );
};

export default NewProduct;