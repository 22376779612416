import Body from './body';
function App() {

    return ( 
    <>
    
        <Body/>
        
    </>

  );
}

export default App;
