import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { motion } from  'framer-motion';
import './home.css';


export default function Navbar({linkedin,github}) {
    const [changeVisible,setVisible] = useState(false);
    const navdataRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const [isNavBarOpen, setIsNavBarOpen]=useState(false);

    const changeBar=()=>{toggleButtonRef.current.classList.toggle("change");};
    const changeVisiblity = ()=>{setVisible(prevState => !prevState);};
    const navbarControl = ()=>{changeBar();changeVisiblity();setIsNavBarOpen(!isNavBarOpen)};

    const [minWidth, setMinWidth] = useState(0);
    const [maxWidth, setMaxWidth] = useState(0);

    useEffect(() => {
     function handleResize() {
       setMinWidth(Math.min(window.innerWidth, maxWidth));
       setMaxWidth(window.innerWidth);
      }

     handleResize();
     window.addEventListener('resize', handleResize);

     return () => {
       window.removeEventListener('resize', handleResize);
     };
    }, []);
    const NavbarDataList=['home','stats','projects','features','team','contact'];
    const toggleMenu = (event) => {
        if ((toggleButtonRef.current)&&toggleButtonRef.current.contains(event.target)) { // Check if the ref has a current value (button exists)
        navbarControl();
      }};
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if ((navdataRef.current && toggleButtonRef.current && !navdataRef.current.contains(event.target)&&!toggleButtonRef.current.contains(event.target))&& isNavBarOpen&&(maxWidth<=1100)) {
          navbarControl();
          };
      };

      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);   
      };
    }, [navdataRef,toggleButtonRef,isNavBarOpen]);
  
    const navdata = [{id:1,name:'home'},{id:2,name:'stats'},{id:3,name:'features'},{id:4,name:'projects'},{id:5,name:'team'},{id:6,name:'contact'}];

    function openLink(url){return function(){window.open(url,'_blank');};};

    const NavbarData = maxWidth<=1100?<div className="text-white element-with-margin ">
      <button
      type="button"
      className={`navbar-toggler ${maxWidth>1100?'invisible':'visible'}`}
      onClick={toggleMenu} ref={toggleButtonRef}
      style={{cursor:"pointer"}} >
      <div className={`hamburger`}>
        <div className="bar bar1 " > </div>
        <div className="bar bar2 "> </div>
        <div className="bar bar3 "> </div>
      </div>
    </button>
    </div>      :      <div className={`  ms-auto text-center navbar-brand `}>{/*${maxWidth>900?'visible':'invisible'}       flex-grow-1 --> this is to make it in center to right*/}
    {
  NavbarDataList.map((item, index) => (
    <span
      className="px-4 text-capitalize"
      key={index}  // Added key for React optimization
    >
      <a href={'#' + item} style={{ textDecoration: "none", color: "white" }}>
        {item}
      </a>
    </span>
  ))
}
        <motion.span className='px-4 ms-4' >
        <motion.img
                    src="static/github-mark-white.svg"
                    style={{ width: 40 }}
                    className="round-pill"
                    onClick={isNavBarOpen?()=>{navbarControl();openLink(github)();}:()=>{openLink(github)();}}
                    alt="Quantum Soft Dev Github"
                    whileHover={{scale:1.2}}
                  />
        </motion.span>
        <motion.span className='px-4 ms-2'>
        <motion.img
                    src="static/LI-In-Bug.WebP"
                    style={{ width: 40 }}
                    className="round-pill"
                    onClick={isNavBarOpen?()=>{navbarControl();console.log('working');openLink(linkedin)();}:()=>{openLink(linkedin)();}}
                    alt="Quantum Soft Dev Linkedin"
                    whileHover={{scale:1.2}}
                  />
        </motion.span>
      </div>;
    /*const handleLinkClick = (url)=>{
      if(isNavBarOpen){
        navbarControl();
      }
      openLink(url);
    };*/
  return (
    <>
  {/*nav start*/}
  <nav
    className="navbar navbar-dark pt-2 "
    style={{ backgroundColor: "rgba(0,0,0)" }}>
    <div className=" d-flex container-fluid justify-content-between ">
      <div className="text-light"><span className='h3 m-4' tooltip="quantumsoftdev"><img  src='QMD.WebP' alt="logo" className="rounded-circle me-3 " style={{height:'40px',width:'40px'}} />
      {maxWidth<=442?'QMD':'Quantum Soft Dev' }
      </span></div>{/*text-warning*/} 

          {NavbarData}   
    </div>
  </nav>
  {/*nav end*/}
  {/*nav dropdown start*/}
  <div
    className={`${maxWidth<=600?'h-100':''} container-fluid bg-dark border-bottom border-white border-2 position-absolute overlay shadow-lg ${ changeVisible ? 'visible' : 'invisible'}`}
    style={{ zIndex: 1 }}  id="navdata" ref={navdataRef}>
    {/*this id name is created*/}
    <div className="container">
      <div className="row">
        <div className="col-md-6 p-3 ">
          <ul className="navbar-nav text-start" style={{ width: "100%" }}>
            {navdata.map((data)=>{ return(
              <li  className={data.name!='contact'?" border-bottom border-light ":""} style={{ width: "80%" }} key={data.id} >
              <a className="nav-link text-uppercase " href={"#"+data.name} onClick={isNavBarOpen?navbarControl:()=>{}}>{data.name}</a></li>   );  })}
 
          </ul>
        </div>
        <div className="col-md-6 p-3 text-white justify-content-center">
          <table style={{ height: "100%", width: "100%" }}>
            <tbody>
              <tr className="">
                <td className="align-bottom text-end">
                  
                  <motion.img
                    src="static/github-mark-white.svg"
                    style={{ width: 40 }}
                    className="round-pill"
                    onClick={isNavBarOpen?()=>{navbarControl();openLink(github)();}:()=>{}}
                    alt="QuantumSoftDev Github"
                    whileHover={{scale:1.2}}
                  />
                </td>
                <td className="align-bottom text-start ps-4">
                  
                  <motion.img
                    src="static/LI-In-Bug.WebP"
                    style={{ width: 40 }}
                    className="round-pill"
                    onClick={isNavBarOpen?()=>{navbarControl();console.log('working');openLink(linkedin)();}:()=>{}}
                    alt="QuantumSoftDev Linkedin"
                    whileHover={{scale:1.2}}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  {/*nav dropdown end*/}


</>

  )
}
//rfc
