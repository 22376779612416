import React,{useState,useEffect} from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './animation.css';


const innerImg = {
  borderRadius: '15px',
  objectFit: "cover",
  width: '90%',/*window.screen.width<=480?'320px':'90%',320px*/
  height: 'auto'/*window.screen.width<=480?'180px':'auto'180px */
};

const data = [{ id:1,img: 'Projects/1.WebP' },
   { id:2,img: 'Projects/2.WebP' },
    { id:3,img: 'Projects/3.WebP' },
     { id:4,img: 'Projects/4.WebP' },
      { id:5,img: 'Projects/5.WebP' },
       { id:6,img: 'Projects/6.WebP' }];

const Product = () => {
  const [minWidth, setMinWidth]= useState(0);
  const [maxWidth, setMaxWidth]= useState(0);

  useEffect(()=>{
    function handleResize() {
      setMinWidth(Math.min(window.innerWidth, maxWidth));
      setMaxWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return()=>{
      window.removeEventListener('resize',handleResize);
    };
  },[]);

  const bgImage = 'project-bg.WebP';
  const bgStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    height: minWidth>=1000?'100%':'auto',
    width: '100%'
  };
  

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed:2000,
    responsive:[{
      breakpoint:900,settings:{
        slidesToShow:2,
      }
    },{breakpoint:700,settings:{
      slidesToShow:1
    }}]
  };

  return (
    <div className="w-3/4 m-auto text-center" style={bgStyle} id='projects'>
      <div className='pt-5' style={{ backdropFilter: 'brightness(20%)' }}>
      <span className='text-uppercase h1 text-light m-6 bordered-text '>Projects</span>
      <div className="" style={{  paddingLeft: '50px',paddingRight: '50px' }}>
        

        <Slider {...settings}>
          {data.map((d) => (
            <div className='py-4 d-flex justify-content-center ' key={d.id}>
              <img src={d.img}  style={innerImg}/>
            </div>
          ))}
        </Slider>
      </div>
      </div>
    </div>
  );
};

export default Product;