import React from 'react'
import { useState, useEffect} from 'react';
import {Slide} from "react-awesome-reveal";
import {motion} from "framer-motion";
//import ScrollAnimation  from 'react-animate-on-scroll';

function Features() {
  const [minWidth, setMinWidth]= useState(0);
  const [maxWidth, setMaxWidth]= useState(0);
  const [show,setShow]=useState(0);

  useEffect(()=>{
    function handleResize() {
      setMinWidth(Math.min(window.innerWidth, maxWidth));
      setMaxWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return()=>{
      window.removeEventListener('resize',handleResize);
    };
  },[]);
  const featureClick=(index)=>{
    setShow(prevShow=>(prevShow !== index ? index:0));
  };
  
  const desc = [
  <><hr/><ul className='mb-2 list-unstyled'>
    <li className='m-2'><span className='lead'>Seamless performance :</span> Smooth operation across platforms</li>
    <li className='m-2'><span className='lead'>Consistent Experience :</span> Uniform on Windows, macOS, Linux, and iOS</li>
    <li className='m-2'><span className='lead'>Cost-Effective :</span> Single codebase saves time and money</li>
    </ul></>,
  <><hr/><ul className='mb-2 list-unstyled'>
  <li className='m-2'><span className='lead'>Robust & Scalable :</span> Built for growth</li>
  <li className='m-2'><span className='lead'>Modern Tech :</span> Uses HTML5, CSS5, JavaScript, React, Next.js, MERN stack</li>
  <li className='m-2'><span className='lead'>Responsive Design:</span> Optimized for all devices</li>
  <li className='m-2'><span className='lead'>Attractive UI:</span> Designed with Bootstrap and Tailwind</li>
  </ul></>,
    <><hr/><ul className='mb-2 list-unstyled'>
    <li className='m-2'><span className='lead'>End-to-End Development :</span> From concept to deployment</li>
    <li className='m-2'><span className='lead'>Latest Tools :</span> Kotlin and Flutter for Android, Flutter for iOS</li>
    <li className='m-2'><span className='lead'>Hight-Quality :</span> User-friendly and platform-compliant apps</li>
    </ul></>,
  <><hr/><ul className='mb-2 list-unstyled'>
  <li className='m-2'><span className='lead'>Industry Solutions :</span> Tailored for various sectors</li>
  <li className='m-2'><span className='lead'>Cross-Platform :</span> Compatible with Windows, macOS, and Linux</li>
  <li className='m-2'><span className='lead'>User-Friendly :</span> Powerful yet easy to use</li>
  </ul></>
  ];
  const featuresList = [<div className='d-flex justify-content-md-start  col-12 m-1 my-2 h-4 text-md-start text-start'>  
    <motion.div whileHover={{scale:1.1}}  onClick={()=>{featureClick(1)}} className={`${maxWidth>='768'?'w-50':'w-100'} border ps-4 p-2 `} style={roundedBorder}><span className='h-1'>Cross Platform Applications</span>{show==1?desc[0]:""}</motion.div>
    </div>,<div className='d-flex justify-content-md-end col-12 m-1 my-2 h-4 text-md-start text-start'> 
          <motion.div whileHover={{scale:1.1}} onClick={()=>{featureClick(2)}} className={`${maxWidth>='768'?'w-50':'w-100'} border ps-4 p-2 `} style={roundedBorder}><span  className='h-1'> Web Applications </span>{show==2?desc[1]:""}</motion.div>
          </div>,<div className='d-flex justify-content-md-start  col-12 m-1 my-2 h-4 text-md-start text-start'>  
    <motion.div whileHover={{scale:1.1}} onClick={()=>{featureClick(3)}} className={`${maxWidth>='768'?'w-50':'w-100'} border ps-4 p-2 `} style={roundedBorder}><span  className='h-1'>Android and iOS Applications</span>{show==3?desc[2]:""}</motion.div>
    </div>,<div className='d-flex justify-content-md-end  col-12 m-1 h-4 text-md-start text-start'> 
          <motion.div whileHover={{scale:1.1}} onClick={()=>{featureClick(4)}} className={`${maxWidth>='768'?'w-50':'w-100'} border ps-4 p-2 `} style={roundedBorder}><span className='h-1'>Desktop Applications</span>{show==4?desc[3]:""}</motion.div>
          </div>];
  const directions = ['left','right','left','right','left','right'];

  return (
    <div className=' content-fluid text-center p-2 pt-3 ' style={styleFeatures} id='features'>
      <div className='rounded text-light  h-100 p-2' style={styleFeaturesBody}>
       <span className=' h1 text-uppercase'style={{text:"rgb(165,165,165)"}}>features</span>
       <hr/>
       <div className='row mt-4 px-5'>
        {featuresList.map((item,index)=>(<Slide 
        triggerOnce
        direction={directions[index]}
        key={index}
        fraction={0.3}
        delay={(index*200)+10}
        >{item}</Slide>))}
{/*     ${index%2===0?'text-start':'text-end'}
  <div className='d-flex justify-content-md-end col-12 m-1 my-2 h-4 text-md-start text-start'> 
          <div className={`${maxWidth>='768'?'w-50':'w-100'} border ps-4 p-2 `} style={roundedBorder}><span className='h-1'> App Development </span></div>
          </div>
        <div className='d-flex justify-content-md-start  col-12 m-1 my-2 h-4 text-md-start text-start'>  
          <div className={`${maxWidth>='768'?'w-50':'w-100'} border ps-4 p-2 `} style={roundedBorder}><span className='h-1'>Responsive Design</span></div>
          </div>
        <div className='d-flex justify-content-md-end  col-12 m-1 h-4 text-md-start text-start'> 
          <div className={`${maxWidth>='768'?'w-50':'w-100'} border ps-4 p-2 `} style={roundedBorder}><span className='h-1'>Cross Platform</span></div>
          </div> */}
       </div>
      </div>
      
    </div>
  )
}

export default Features
const styleFeatures = {
  background:'rgb(0,0,0)'
};
const roundedBorder = {
  borderRadius: "12px",
  paddingLift: "1vw"
};
const styleFeaturesBody = {
background: 'rgb(37,37,37)'
};