import React from 'react';
import { useState, useEffect } from 'react';

function Team() {
  const [minWidth, setMin1Width] = useState(0);
  const [maxWidth, setMax1Width] = useState(0);
  const srdLinkedin = "https://www.linkedin.com/in/shivamrajdubey/";
  const kamyaLinkedin = "https://www.linkedin.com/in/kamya-singh-11b317219/";
  const kartikayLinkedin = "https://www.linkedin.com/in/kumarkartikayshankar/";
  const team = [
    {'img':'kartikay.WebP','name':'Kumar Kartikay Shankar','position':'Co-Founder & CEO','linkedin':'https://www.linkedin.com/in/kumarkartikayshankar/'},
    {'img':'shivam.WebP','name':'Shivam Raj Dubey','position':'Co-Founder & CTO','linkedin':'https://www.linkedin.com/in/shivamrajdubey/'},
    {'img':'kamya.WebP','name':'Kamya Singh','position':'Co-Founder & CFO','linkedin':'https://www.linkedin.com/in/kamya-singh-11b317219/'}
  ]

  useEffect(() => {
    function handleResize1() {
      setMin1Width(Math.min(window.innerWidth, maxWidth));
      setMax1Width(window.innerWidth);
    }

    handleResize1();
    window.addEventListener('resize', handleResize1);

    return () => {
      window.removeEventListener('resize', handleResize1);
    };
  }, []);
  const imgStyle = {
    boxShadow: "5px 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    width: maxWidth >= '768' ? '10vw' : '30vw',
    height: maxWidth >= '768' ? '10vw' : '30vw',
    
  };
  function openLink(url){return function(){window.open(url,'_blank');};};
  return (
    <div className="container-full m-0" id='team'>
      <div className="m-2 bg-light rounded text-center px-2 py-1">
        <span className="h1 text-uppercase">Team </span>
        <hr />
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">

            {team.map((d)=>{

             return(<div className="col-6 col-sm-5 col-md-3 my-1 mx-2 bg-light p-2">
              <img src={d.img} className="rounded-circle" style={imgStyle} alt="SRD" />
              <div className="card-body text-center">
                <span className="text-uppercase">{d.name}</span><br />
                <span className="text-uppercase h6">{d.position} </span><br />
                <span><img
                src="static/LI-In-Bug.WebP"
                style={{ width: 40, cursor: "pointer" }}
                className="round-pill"
                onClick={openLink(d.linkedin)}
                alt="Shivam Raj Dubey (Co-Founder & CTO Quantum Soft Dev)"
                /></span>
              </div>
            </div>)

            })}
            

          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;

const roundedBorder = {
  borderRadius: "10px"
};

