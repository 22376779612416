import React, { Component,useState,useEffect } from 'react';
import {TypeAnimation} from 'react-type-animation';

function CallToAction() {
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth]= useState(0);
  useEffect(()=>{
    function handleResize(){
      setMinWidth(Math.min(window.innerWidth,maxWidth));
      setMaxWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener('resize',handleResize);

    return()=>{
      window.removeEventListener('resize',handleResize);
    };
  },[]);
  const bgImage = "/static/Mountain.jpg"; {/*`static/bg-img${maxWidth<=850?3:2}.WebP`;*/}
  const imageStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    //position: 'fixed',
    backgroundAttachment: 'fixed',
    height: '95vh',//maxWidth>='850'?'100vh':'60vh',
    width: '100%',
    padding: 0,
    margin: 0,
    //zIndex: -1
  };
  const containerStyle = {
    height: '80vh',
    width: '100%',
    padding: "0",
    marginTop:"3"
  };
  const callToAction = {
    background: 'rgb(0, 0, 0, 0.1)',
    height: '95vh',
    width: '100%',
    display: 'flex', // Flexbox for centering
    justifyContent: 'center', // Horizontally center
    alignItems: 'center', // Vertically center
    //margin: 'auto'
  };

  return (
    <div>
      {/*<div style={imageStyle} />*/}
    <div className="container-fluid d-flex " style={imageStyle}>
      <div className="text-white container-fluid d-flex justify-content-center h1" style={callToAction}>
        <TypeAnimation 
        sequence={[
          "The future is already here — it's just not evenly distributed",
          //5000,
          //'',
          //1000
        ]}
        wrapper='span'
        cursor={false}
        repeat={0}
        />  
      
      </div>
    </div>
    </div>
  );
}

export default CallToAction;
