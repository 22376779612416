import React, { Fragment, useEffect,useRef,useState } from 'react';
import { motion, useAnimation,useScroll,useTransform, useSpring, MotionValue  } from 'framer-motion';
import { useInView } from 'react-intersection-observer'; // Hook to detect if in view
import Home from './Components/Home';
import FooterWeb from './Components/FooterWeb';
import ContactWeb from './Components/ContactWeb';
import Stats from './Components/Stats';
import Features from './Components/Features';
import Team from './Components/Team';
import Product from './Components/Product';
import useScrollSnap from 'react-use-scroll-snap';
import Highlights from './Components/Home/Highlights';
import Alert from './Components/Home/Alert';

import axios from 'axios';

function AnimatedSection({ children }) {
  console.log(children[0]['key']);
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 }); // Adjust threshold for when animation triggers
 

  useEffect(() => {
    if (inView) {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8 },
      });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }} // Initial hidden state
      animate={controls} // Controls animation based on scroll
    >
      {children}
    </motion.div>
    /*()=>{return(children[0]['key']=='home'?<motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }} // Initial hidden state
      animate={controls} // Controls animation based on scroll
    >
      {children}
    </motion.div> : <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 25 }} // Initial hidden state
      animate={controls} // Controls animation based on scroll
    >
      {children}
    </motion.div>) }*/
  );
}




function Body() {
  const { scrollYProgress } = useScroll();
  const linkedin_url = "https://www.linkedin.com/company/quantum-softdev";
  const github_url = "https://github.com/QuantumSoftDev";

  const scrollRef = useRef(null);
  //useScrollSnap({ref:scrollRef,duration:50,delay:20});
  useEffect(() => {
    // Scroll to the anchor if the URL contains a hash
    if (window.location.hash) {
      setTimeout(() => {
        const targetElement = document.getElementById(window.location.hash.substring(1));
        if (targetElement) {
          window.scrollTo({
            top: targetElement.getBoundingClientRect().top + window.pageYOffset,
            behavior: 'smooth'
          });
        }
      }, 2000); // Adjust delay to ensure page content is fully loaded
    }
  }, []);
const [count,setCount]=useState('');

useEffect(() => {
  const fetchCount = async () => {
    try {
      const response = await axios.post('https://backend.quantumsoftdev.in/api/countMeter');
      console.log(response);
      setCount(response.data.count);
    } catch (error) {
      console.error("Error fetching count:", error);
    }
  };

  fetchCount();
}, []);
/*useEffect(() => {
  const handleWindowLoad = async () => {
    try {
      //const response = await axios.post('http://localhost:4000/api/formdata', formsubmit);
      const response = await axios.post('https://backend.quantumsoftdev.in/api/countMeter');//{request:"count"}
      console.log(response);

      setCount(response.data.count);
    } catch (error) {
      console.log("some error");
    }
  };

  window.addEventListener('load', handleWindowLoad);

  // Cleanup the event listener on component unmount
  return () => window.removeEventListener('load', handleWindowLoad);
}, []);*/

  /*window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      if (window.location.hash) {
        const element = document.getElementById(window.location.hash.substring(1));
        if (element) {
          window.scrollTo({
            top: element.getBoundingClientRect().top + window.pageYOffset,
            behavior: 'smooth'
          });
        }
      }
    }, 500); // Adding more delay for mobile browsers
  });*/
  
  

  const [isAlertVisible, setIsAlertVisible] = useState(true);

  // Function to hide the alert
  const hideAlert = () => {
    setIsAlertVisible(false);
  };
  // List of components to animate
  const animateList = [
    isAlertVisible && <Alert onHideAlert={hideAlert} key="alert" />,
    <Home linkedin={linkedin_url} github={github_url} key="home" />,
    <Highlights read={count}/>,
    <Stats key="stats" />,
    <Product key="product" />,
    <Features key="features" />,
    <Team key="team" />,
    <ContactWeb key="contact" />,
  ];

  return (
    <><div ref={scrollRef}>
      
      <Fragment>
        {/* Looping through the animateList and wrapping in AnimatedSection */}
        {animateList.map((item,key) => (
          (true)?
          <AnimatedSection key={key}>
          {item} {/* Pass component as child to AnimatedSection */}
          </AnimatedSection>:{item}
        ))}
      </Fragment>
      <FooterWeb linkedin={linkedin_url} github={github_url} />
      </div>
    </>
  );
}

export default Body;