import React from 'react'
import Navbar from './Home/Navbar'
import CallToAction from './Home/CallToAction'
import Highlights from './Home/Highlights'
import Alert from './Home/Alert'

//import inspector from 'inspector';

function Home({linkedin,github}) {
  return (
    <div id='home'>
       {/*<Alert/>*/}
       
      <Navbar linkedin={linkedin} github={github}/>
     {/* <Highlights />   */}   
      <CallToAction />
    </div>
  )
}

export default Home
