import React,{useState, useEffect,useRef} from 'react';
import './stats.scss';
import {useSpring, animated} from "react-spring";


function Problem_Solved({n}){
  const {number}= useSpring({
    from: {number:0},
    number: n,
    delay: 200,
    config: {mass:1,tension: 20, friction: 10},
  });
  return <animated.div>{number.to((n)=> n.toFixed(0))}</animated.div>;
};
function Ideas({n}){
  const {number}= useSpring({
    from: {number:0},
    number: n,
    delay: 200,
    config: {mass:1,tension: 20, friction: 10},
  });
  return <animated.div>{number.to((n)=> n.toFixed(0))}</animated.div>;
};
function No_of_products({n}){
  const {number}= useSpring({
    from: {number:0},
    number: n,
    delay: 200,
    config: {mass:1,tension: 20, friction: 10},
  });
  return <animated.div>{number.to((n)=> n.toFixed(0))}</animated.div>;
};
function Stats() {
  const [isVisible1,setIsVisible1]= useState(false);
  const [isVisible2,setIsVisible2]= useState(false);
  const [isVisible3,setIsVisible3]= useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisible1(true);
      }
    }, { threshold: 1.0 });

    const element = document.getElementById('problemsolved');
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisible2(true);
      }
    }, { threshold: 1.0 });

    const element = document.getElementById('ideas');
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisible3(true);
      }
    }, { threshold: 1.0 });

    const element = document.getElementById('projects');
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);
    const statsStyle = {
      height:'auto',
      background: 'white'
    };
    const marginZeroTop = {
      margin:"0",
      borderRadius: "15px 15px 0px 0px"
    };
    const marginZeroBottom = {
      margin:"0",
      borderRadius: "0px 0px 15px 15px"
    };
    const shadowCard = {
      boxShadow: "5px 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      borderRadius: "15px 15px 15px 15px"
      //filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.25))"
    };
  return (
    <>
        <div className="contanier-fluid   album py-5 text-center " style={statsStyle} id="stats">
          <div className="container " >
            <div className=" row justify-content-center g-3">
              <div className="col-xl-3  col-sm-6 col-7 ">
                <div className="card   " style={shadowCard}>{/*border border-dark */}
                  <div className="row bg-dark py-2 " style={marginZeroTop}> {/*rounded-top */}
                    <span className="h4 text-light">PROBLEM SOLVED</span>
                  </div>
                  <div className="row bg-light py-2 " style={marginZeroBottom} id='problemsolved'>{/*rounded-bottom */}
                    <span className=" display-6 ">{isVisible1?<Problem_Solved n={100} />:'0'}</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3  col-sm-6 col-7  ">
                <div className="card " style={shadowCard}> {/*rounded */}
                  <div className="row bg-dark py-2 " style={marginZeroTop}>
                    <span className="h4 text-light ">IDEAS</span>
                  </div>
                  <div className="row bg-light py-2 " style={marginZeroBottom} id='ideas'>
                    <span className="display-6">{isVisible2?<Ideas n={10}/>:'0'}</span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3  col-sm-6 col-7 ">
                <div className="card " style={shadowCard}>
                  <div className="row bg-dark py-2 " style={marginZeroTop}>
                  <span className="h4 text-light">NO OF PROJECT</span>
                  </div>
                  <div className="row bg-light py-2 " style={marginZeroBottom} id='projects'>
                  <span className="display-6">{isVisible3?<No_of_products n={9}/>:'0'}</span>
                  </div>
                </div>
              </div>
            </div>
         </div>
        </div>
      
    </>
  )
}

export default Stats