import React, { useState, useEffect } from 'react';
import NewProduct from './NewProduct';
import axios from 'axios';
import {useSpring, animated} from "react-spring";

export default function Highlights({read}) {
  const [minWidth, setMinWidth] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [count, setCount] = useState(0); // Define count state

  useEffect(() => {
    function handleResize() {
      setMinWidth(Math.min(window.innerWidth, maxWidth));
      setMaxWidth(window.innerWidth);
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [maxWidth]); // Add maxWidth as a dependency

  const addCount = async () => {
    try {
      const response = await axios.post('https://backend.quantumsoftdev.in/api/count', { request: "count" });
      //const response = await axios.post('http://localhost:3000/api/count', { request: "count" });
      console.log(response.data);
      setCount(response.data.id); // This will now work since count is defined
    } catch (error) {
      console.error("Error adding count:", error); // Improved error handling
    }
  };

  const imgStyle = {
    height: "50%",
    width: "auto"
  };

  const downloadApk = () => {
    addCount(); // Call addCount when downloading APK
    const link = document.createElement('a');
    link.href = 'arise.apk'; // Replace with your APK file path
    link.setAttribute('download', 'arise.apk'); // Forces the browser to download the file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Cleanup the link element
  };

  const amazonLink = "https://www.amazon.com/dp/B0DJFS8KQG/ref=apps_sf_sta";
  function openLink(url) {
    return function () {
      addCount();
      window.open(url, '_blank');
    };
  }

  const marginZeroTop = {
    margin: "0",
    borderRadius: "15px 15px 0px 0px"
  };
  const marginZeroBottom = {
    margin: "0",
    borderRadius: "0px 0px 15px 15px"
  };
  const shadowCard = {
    boxShadow: "5px 8px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "15px"
  };

  const [isVisible,setIsVisible]= useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsVisible(true);
      }
    }, { threshold: 1.0 });

    const element = document.getElementById('countdownloads');
    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, []);
  function NoOfDownloads({n}){
    const {number}= useSpring({
      from: {number:0},
      number: n,
      delay: 200,
      config: {mass:1,tension: 20, friction: 10},
    });
    return <animated.div>{number.to((n)=> n.toFixed(0))}</animated.div>;
  };

  return (
    <div id='newProduct'>
      <div> <div>
      <div className='container-fluid' style={{ background: "rgb(37,37,37)" }} >
        <div className='mx-4 my-2 text-center row p-4'>
          {/* Logo or Image Section */}
          <div className="col-lg-3 col-md-4 item zoom-on-hover hide-it d-flex justify-content-center align-items-center">
            <a href="#"><img className="rounded-circle img-fluid image"  src="ariselogo.png" alt="Arise Logo" /></a>
          </div>

          {/* Download Section */}
          <div className='col-lg-4 col-md-8 col-12 text-white d-flex flex-column justify-content-center '>
            <div className='row'>
              <div className='my-2' style={{ cursor: "pointer" }}>
                <span className='h3' onClick={downloadApk}>
                  Direct Download {maxWidth <= 900 ? <br /> : ''}
                  <img style={{ height: "50px", width: "50px" }} src='down.WebP' alt="Download icon" />
                </span>
                <br />
              </div>
              <div className='my-3' style={{ cursor: "pointer" }}>
                <span className='h3' onClick={openLink(amazonLink)}>
                  Download from {maxWidth <= 900 ? <br /> : ''}
                  <img style={{ height: "50px", width: "50px" }} src='amazonapp.WebP' alt="Amazon icon" />
                </span>
              </div>
            </div>
            <div className='d-flex flex-row  justify-content-center m-4'>
            <div className={`  card ${maxWidth>900?'w-50':'w-100'}`} style={shadowCard}> {/*rounded */}
                  <div className="row bg-dark py-2 " style={marginZeroTop}>
                    <span className="h4 text-light ">No of Downloads</span>
                  </div>
                  <div className="row bg-light py-2 " style={marginZeroBottom} id='countdownloads'>
                    <span className="display-6 text-dark">{isVisible?<NoOfDownloads n={read}/>:'0'}</span>
                  </div>
                </div>

            </div>
          </div>

          {/* NewProduct Component Section (acting as the card) */}
          <div className='col-lg-4 col-md-8 width-100 '>
            <NewProduct />
          </div>
        </div>
      </div>
      </div></div>
    </div>
  )
}
